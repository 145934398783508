<template>
  <div class="step__body">
    <DimensionOption :option="step.width" :option-dim="dimension.width" type="width" />
    <DimensionOption :option="step.length" :option-dim="dimension.length" type="length" />
    <DimensionOption v-if="!dimension.height_additional" :option="step.height" :option-dim="dimension.height" type="height"/>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import DimensionOption from './dimensionOption'
export default {
  name: 'DimensionGlass',
  components: {
    DimensionOption
  },
  setup () {
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const dimension = computed(() => {
      const steps = store.getters['configurator/steps']
      const options = steps.find(s => s.model_step).options
      const option = options.find(o => o.selected)
      return option.dimension
    })
    return {
      step,
      dimension
    }
  }
}
</script>
